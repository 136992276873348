import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Skill from "./Skill";

export default function SkillsMenu() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 8,
            slidesToSlide: 4,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6,
            slidesToSlide: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1,
        },
    };

    return (
        <div className="text-center shadow-2xl py-8 rounded-2xl">
            <h1 className="text-3xl font-black my-4">My Skills</h1>
            <Carousel
                responsive={responsive}
                infinite={true}
                showDots={true}
                swipeable={true}
                // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                centerMode={true}
                className="py-12 items-center"
            >
                <Skill skill="Javascript" img="js" />
                <Skill skill="React" img="react" />
                <Skill skill="Flutter" img="flutter" />
                <Skill skill="React Native" img="react" />
                <Skill skill="Swift" img="swift" />
                <Skill skill="UIKit" img="apple" />
                <Skill skill="Python" img="python" />
                <Skill skill="PHP" img="php" />
                <Skill skill="GitHub" img="github" />
                <Skill skill="C#" img="csharp" />
                <Skill skill="Unity" img="unity" />
                <Skill skill="Java" img="java" />
                <Skill skill="C++" img="cpp" />
            </Carousel>
        </div>
    );
}
