import Header from "./components/Header";
import HomeHero from "./components/HomeHero";
import SkillsMenu from "./components/SkillsMenu";

export default function App() {
    return (
        <div>
            <Header/>
            <HomeHero/>
            <SkillsMenu/>
        </div>
    )
}

