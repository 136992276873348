export default function HomeHero() {
    return (
        <div className="my-7 py-14 rounded-2xl justify-evenly shadow-2xl block lg:flex items-center text-center">
            <img src="images/computer.svg" className={"mx-auto w-52 lg:w-96 mb-6"} />
            <div className={"mx-auto px-5"}>
                <h1 className="font-black text-4xl lg:text-6xl my-4">Hi, I'm Alex :)</h1>
                <h2 className="text-2xl">Software Developer</h2>
                <ul className="bold md:flex justify-center my-8 text-sm lg:text-xl">
                    <li className="font-bold mx-3">Web</li>
                    <p className={"hidden md:inline"}>|</p>
                    <li className="font-bold mx-3">Mobile</li>
                    <p className={"hidden md:inline"}>|</p>
                    <li className="font-bold mx-3">Games</li>
                </ul>
                <a href="http://www.github.com/emcassi">
                    <button className="bg-purple-800 text-white mx-auto py-3 px-6 lg:px-12 font-bold text-sm lg:text-xl rounded-3xl flex items-center">
                        <img className="w-6 mr-4" src="images/github.png" />
                        See My Work
                    </button>
                </a>
            </div>
        </div>
    );
}
