import {Link} from "react";

export default function Header() {
    return (
        <div className="p-8 bg-violet-800 text-white left-0 right-0">
            <div className="justify-evenly flex items-center">
                <div>
                    <h1 className="text-lg md:text-xl font-black">Alex Wayne</h1>
                </div>
                <div>
                    <ul className="flex">
                        <a href={"/"}>
                            <li className="mx-4 menu-item border-b-2 border-transparent hover:border-white hover:font-bold">
                                Home
                            </li>
                        </a>

                        <a href="https://www.github.com/emcassi">
                            <li className="mx-4 menu-item border-b-2 border-transparent hover:border-white hover:font-bold">
                                GitHub
                            </li>
                        </a>

                    </ul>
                </div>
            </div>
        </div>
    );
}
