
export default function Skill(props) {
    return (
        <div className="w-fit justify-center">
            <img
                className="mx-auto"
                style={{ width: "96px", height: "96px" }}
                src={`images/skills/${props.img}.svg`}
            />
            <h3 className="mt-6 text-xl font-bold">{props.skill}</h3>
        </div>
    );
}
